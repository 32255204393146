import RefreshIcon from "@mui/icons-material/Refresh"
import { Button, ButtonProps } from "@mui/material"
import { useSnackbar } from 'notistack'
import { FC, useEffect } from "react"
import { useAppUpdates } from "src/app-updates/app-update-context"

export const AppUpdatePopup: FC = () => {
    const { isUpdateAvailable, updateApp } = useAppUpdates()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (!isUpdateAvailable) {
            return
        }

        enqueueSnackbar("New version installed", {
            key: "app-update",
            action: <ReloadAppButton onClick={updateApp}/>,
            persist: true,
            variant: "info",
            children: "Hello"
        })
    }, [isUpdateAvailable, updateApp, enqueueSnackbar])

    return <>
    </>
}

const ReloadAppButton: FC<ButtonProps> = ({
    ...props
}) => {
    return <Button
        startIcon={<RefreshIcon />}
        color="inherit"
        {...props}
    >
        Reload
    </Button>
}