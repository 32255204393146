import { Dialog, DialogProps, Theme, useMediaQuery } from "@mui/material"
import { FC } from "react"


export const ResponsiveDialog: FC<DialogProps> = ({
    ...props
}) => {
    const fullscreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

    return <Dialog
        fullScreen={fullscreen}
        {...props}
    />
}