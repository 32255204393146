import CheckIcon from "@mui/icons-material/Check";
import PrintIcon from "@mui/icons-material/Print";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, CircularProgress, ClickAwayListener, Paper, Popper, Stack, SxProps } from "@mui/material";
import { useRef } from "react";
import { FC, ReactElement, ReactNode, useState } from "react";
import { ResponsiveIconButton } from "src/common/ResponsiveIconButton";
import { usePrintingContext } from "src/printing/printing-context";


export const PrinterStatusIcon: FC = () => {
    const { status, reconnect } = usePrintingContext()

    switch (status) {
        case "connecting": return <ConnectingIcon />
        case "printing": return <PrintingIcon />
        case "error": return <ErrorIcon reconnect={reconnect} />
        case "no-format": return <NoFormatIcon />
        case "ready": return <ReadyIcon reconnect={reconnect} />
        default: return <></>
    }
}

type ReconnectProp = {
    reconnect: () => void
}

const ConnectingIcon: FC = () => {
    return <LoadingIcon text="Connecting" />
}

const PrintingIcon: FC = () => {
    return <LoadingIcon text="Printing" />
}

const ErrorIcon: FC<ReconnectProp> = ({
    reconnect
}) => {
    return <PrinterIcon
        statusIcon={<WarningIcon color="warning" />}
        popupContent={<Stack alignItems="center">
            Could not connect to printer
            <Button onClick={reconnect}>Reconnect</Button>
        </Stack>}
    />
}

const NoFormatIcon: FC = () => {
    return <PrinterIcon
        statusIcon={<WarningIcon color="warning" />}
        popupContent={<>No print format defined</>}
    />
}

const ReadyIcon: FC<ReconnectProp> = ({
    reconnect
}) => {
    return <PrinterIcon
        statusIcon={<CheckIcon color="success"/>}
        popupContent={<Stack alignItems="center">
            Printer ready
            <Button onClick={reconnect}>Reconnect</Button>
        </Stack>}
    />
}



type LoadingIconProps = {
    text: string
}

const LoadingIcon: FC<LoadingIconProps> = ({
    text
}) => {
    return <PrinterIcon
        statusIcon={<CircularProgress size={16} thickness={8} disableShrink />}
        popupContent={<>{text}</>}
    />
}

type PrinterIconProps = {
    statusIcon: ReactElement<SxProps>
    popupContent: ReactNode
}

const PrinterIcon: FC<PrinterIconProps> = ({
    statusIcon,
    popupContent,
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null)
    const [isOpen, setIsOpen] = useState(false)

    return <>
        <ResponsiveIconButton
            ref={buttonRef}
            onClick={() => setIsOpen(!isOpen)}
            sx={{
                position: "relative"
            }}
        >
            <PrintIcon />
            <Box sx={{
                position: "absolute",
                bottom: -5,
                right: -5,
                "& .MuiSvgIcon-root": {
                    fontSize: "unset !important"
                },
            }}>
                {statusIcon}
            </Box>
        </ResponsiveIconButton>
        <Popper open={isOpen} anchorEl={buttonRef.current}>
            <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <Paper sx={{ p: 2 }}>
                    {popupContent}
                </Paper>
            </ClickAwayListener>
        </Popper>
    </>
}