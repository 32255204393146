import { Autocomplete, AutocompleteProps, FilterOptionsState, ListItem, TextField } from "@mui/material"
import { FC, useCallback, useMemo } from "react"
import { backend } from "src/common/backend"
import { useEvent } from "src/common/event-context"
import { useScanningConfiguration } from "src/settings/scanning-configuration-context"

export type SessionIdInputProps = Partial<Omit<AutocompleteProps<string, false, false, true>, "onChange">> & {
    onChange: (value: string | null) => void
}

const newOptionPrefix = "Create: "
const filterOptions = (options: string[], { inputValue }: FilterOptionsState<string>) => {
    const normalizedInput = normalize(inputValue)

    const filteredOptions = options.filter(it => normalize(it).includes(normalizedInput))

    if (inputValue && !filteredOptions.some(it => normalize(it) === normalizedInput)) {
        filteredOptions.push(`${newOptionPrefix}${inputValue}`)
    }

    return filteredOptions
}
const normalize = (value: string) => value
    .toLowerCase()
    .replace(/[^0-9a-z]*/g, "")

const renderOption = (props: any, option: string) => option.startsWith(newOptionPrefix) ?
    <ListItem {...props} sx={{ ...props.sx, fontStyle: "italic" }}>{option}</ListItem> :
    <ListItem {...props}>{option}</ListItem>

export const SessionIdInput: FC<SessionIdInputProps> = ({
    value,
    onChange,
    ...props
}) => {
    const { sessionNames } = useScanningConfiguration()
    const event = useEvent()
    const addSessionNameRequest = backend.scanning.useAddSessionName()

    const options = useMemo(() => {
        if (!value || sessionNames.includes(value)) {
            return sessionNames
        }
        return [...sessionNames, value]
    }, [value, sessionNames])

    const handleChange = useCallback((_event: any, newValue: string | null) => {
        if (!newValue || sessionNames.includes(newValue)) {
            onChange(newValue || null)
            return
        }

        if (newValue?.startsWith(newOptionPrefix)) {
            newValue = newValue.replace(newOptionPrefix, "")
        }
        onChange(newValue)
        addSessionNameRequest.mutate([event.clientId, event.id, newValue])
    }, [event.clientId, event.id, onChange, sessionNames, addSessionNameRequest])

    return <Autocomplete
        value={value || null}
        onChange={handleChange}
        renderInput={(params) => <TextField
            {...params}
            label="Session"
        />}
        freeSolo
        filterOptions={filterOptions}
        renderOption={renderOption}
        selectOnFocus
        openOnFocus
        autoSelect
        autoHighlight
        options={options}
        {...props}
    />
}