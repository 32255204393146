import { getErrorString } from "@marketpartner/mp-common"
import { BackgroundUpdateItem, BackgroundUpdateState, BackgroundUpdateStorage } from "src/common/background-update/background-update-storage"
import { nChunks } from "src/common/nChunks"

export enum BackgroundUpdateResult {
    Success = "Success",
    Retry = "Retry",
}

export const maxParallelism = 4
export type BackgroundUpdateFunction<T> = (item: T) => Promise<BackgroundUpdateResult | undefined | void>

/**
 * Provides periodic processing of items in batches.
 * 
 * There should be no need to use this directly (see useBackgroundUpdate instead).
 */
export class BackgroundUpdateProcess<T> {
    private stopped = false
    private timerId: NodeJS.Timeout | undefined

    constructor(
        private storage: BackgroundUpdateStorage<T>,
        private updateFrequencySeconds: number,
        private update: BackgroundUpdateFunction<T>
    ) {
        this.startNextTimer()
    }

    private startNextTimer = () => {
        this.timerId = setTimeout(
            async () => {
                if (this.stopped) {
                    return
                }
                await this.updateBatch()
                if (!this.stopped) {
                    this.startNextTimer()
                }
            },
            this.updateFrequencySeconds * 1000
        )
    }

    private updateBatch = async () => {
        const itemsToUpdate = this.storage.getItems()
            .filter(it => shouldUpdate(it.state))
        if (!itemsToUpdate.length) {
            return
        }

        const chunks = nChunks(itemsToUpdate, maxParallelism)

        await Promise.all(chunks.map(chunk =>
            this.updateAll(chunk)
        ))
    }

    stop = () => {
        this.stopped = true
        clearTimeout(this.timerId)
    }

    private async updateAll(items: BackgroundUpdateItem<T>[]) {
        for (const item of items) {
            await this.tryUpdateItem(item)
        }
    }

    private async tryUpdateItem({ id, item }: BackgroundUpdateItem<T>) {
        this.storage.updateState(id, BackgroundUpdateState.Updating)
        try {
            const result = await this.update(item)
            if (result === BackgroundUpdateResult.Retry) {
                this.storage.updateState(id, BackgroundUpdateState.RetryPending)
            } else {
                this.storage.removeItem(id)
            }
        } catch (error) {
            if (looksLikeANetworkFailure(error)) {
                this.storage.updateState(id, BackgroundUpdateState.RetryPending, getErrorString(error))
            } else {
                this.storage.updateState(id, BackgroundUpdateState.Failed, getErrorString(error))
            }
        }
    }
}

function shouldUpdate(state: BackgroundUpdateState) {
    return state === BackgroundUpdateState.Pending || state === BackgroundUpdateState.RetryPending
}

function looksLikeANetworkFailure(error: unknown) {
    if (typeof error === 'object' &&
        error !== null &&
        'request' in error &&
        !('response' in error)) {
        return true
    }
    const stringValue = getErrorString(error).toLowerCase()
    return stringValue.includes("network error") ||
        stringValue.includes("request aborted")
}