import { CircularProgress, CircularProgressProps } from "@mui/material"
import { FC } from "react"


export const LoadingSpinner: FC<CircularProgressProps> = ({
    sx,
    ...props
}) => {
    return <CircularProgress 
        sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%) translateX(-50%)",
            ...sx,
        }}
        {...props}
    />
}