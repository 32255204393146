import { createContext, FC, ReactNode, useContext } from "react";
import { LoginScreen } from "src/login/LoginScreen";
import { TokenLoginState, useTokenLogin } from "src/login/useTokenLogin";

export type LoginContext = TokenLoginState

const context = createContext<LoginContext>(undefined!)

export const useLoginContext = () => useContext(context)

export type LoginContextProps = {
    children: ReactNode
}

export const Login: FC<LoginContextProps> = ({
    children
}) => {
    const loginState = useTokenLogin()

    return <context.Provider value={loginState}>
        {loginState.isLoggedIn ?
            children :
            <LoginScreen />
        }
    </context.Provider>
}