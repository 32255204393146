import { LoadingButton } from "@mui/lab"
import { Collapse, FormHelperText, Stack, TextField, useTheme } from "@mui/material"
import { FC, FormEvent, useState } from "react"
import { useLoginContext } from "src/login/login-context"

export type LoginFormProps = {
    onLogin?: () => unknown
}

export const LoginForm: FC<LoginFormProps> = ({
    onLogin
}) => {
    const [token, setToken] = useState<string>("")
    const login = useLoginContext()

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        login.login(token, onLogin)
    }

    return <form onSubmit={handleSubmit}>
        <Stack spacing={1}>
            <TextField
                label="Access token"
                placeholder="e.g. a1b2c3d4e5f6g7h8"
                value={token}
                onChange={e => setToken(e.target.value)}
            />
            <LoadingButton
                type="submit"
                variant="contained"
                loading={login.isLoggingIn}
            >
                Log in
            </LoadingButton>
            <ErrorMessage errorMessage={login.loginError} />
        </Stack>
    </form>
}

const ErrorMessage: FC<{
    errorMessage?: string
}> = ({ errorMessage }) => {
    const theme = useTheme()

    return <Collapse in={Boolean(errorMessage)}>
        <FormHelperText sx={{
            color: theme.palette.error.main
        }}>
            {errorMessage}
        </FormHelperText >
    </Collapse>
}