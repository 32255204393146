import { CurrentAuthorization, Permission } from "@marketpartner/backend-api"
import { getErrorString } from "@marketpartner/mp-common"
import { useLocalStorage, useUrlState } from "@marketpartner/mp-common-react"
import { useEffect, useState } from "react"
import { backend } from "src/common/backend"


const accessTokenKey = "scanner-access-token"

export function findAccessToken(): string | undefined {
    const tokenJson = localStorage.getItem(accessTokenKey)
    return tokenJson ? JSON.parse(tokenJson) : undefined
}

export type TokenLoginState = {
    isLoggingIn: boolean
    isLoggedIn: boolean
    loginError?: string
    authorization?: CurrentAuthorization
    login: (token: string, onSuccess?: () => unknown) => unknown
    logout: () => unknown
}

export const useTokenLogin = (): TokenLoginState => {
    const [urlToken, setUrlToken] = useUrlState("token")
    const [token, setToken] = useLocalStorage<string>(accessTokenKey, "")
    const [authorization, setAuthorization] = useState<CurrentAuthorization | undefined>()
    const [loginError, setLoginError] = useState<string | undefined>()

    const authQuery = backend.authorization.useGetCurrentAuthorization([], {
        onSuccess: auth => {
            const permissions = Object.keys(auth.permissions)
            // Anonymous
            if (!permissions.length) {
                return
            }
            if (!permissions.includes(Permission.MarkAttendance)) {
                setToken("")
                setLoginError("You do not have permission to access the scanner.")
                return
            }
            if (!auth.singleClientId || !auth.singleEventId) {
                setToken("")
                setLoginError("User is not associated with a single event. Scanner app does not currently work with multiple events.")
                return
            }
            setAuthorization(auth)
        },
        onError: error => {
            setLoginError(getErrorString(error))
        },
    })

    useEffect(() => {
        if (urlToken) {
            login(urlToken)
            setUrlToken("")
        } else if (token) {
            authQuery.refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlToken])

    const login = async (token: string, onSuccess?: () => unknown) => {
        token = token.trim()
        setToken(token)
        setLoginError(undefined)
        if (token) {
            await authQuery.refetch()
            onSuccess?.()
        }
    }
    const logout = () => {
        setToken("")
        setAuthorization(undefined)
        setLoginError(undefined)
    }

    return {
        authorization,
        isLoggingIn: authQuery.isLoading,
        isLoggedIn: Boolean(authorization),
        login,
        logout,
        loginError,
    }
}