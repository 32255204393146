import { ExternalLinkProps, ExternalLink } from "@marketpartner/mp-common-mui";
import { FC } from "react";
import { getAdminUrl } from "src/common/environment";
import { useEvent } from "src/common/event-context";


export type EventAdminLinkProps = Omit<ExternalLinkProps, "href"> & {
    subPath?: string
}

export const EventAdminLink: FC<EventAdminLinkProps> = ({
    subPath,
    ...props
}) => {
    const event = useEvent()
    return <ExternalLink
        href={`${getAdminUrl(event.clientId, event.id, subPath)}`}
        {...props}
    />
}