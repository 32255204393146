import { useFetch } from "@marketpartner/mp-common-react"
import CameraswitchIcon from "@mui/icons-material/Cameraswitch"
import FlipIcon from "@mui/icons-material/Flip"
import { IconButton, IconButtonProps, Stack, SxProps } from "@mui/material"
import QrScanner from "qr-scanner"
import { FC, forwardRef, useRef, useState } from "react"
import { useLockingContext } from "src/locking/locking-context"
import { CameraListMenu } from "src/scanning/CameraList"


export type CameraControlsProps = {
    cameraIsStarted: boolean,
    onFlip: () => unknown,
    cameraId: string
    onChangeCameraId: (id: string) => unknown
    sx?: SxProps
}

export const CameraControls: FC<CameraControlsProps> = ({
    cameraIsStarted,
    onFlip,
    cameraId,
    onChangeCameraId,
    sx,
}) => {
    const cameraListRequest = useFetch(
        async () => cameraIsStarted ? QrScanner.listCameras(true) : undefined,
        [cameraIsStarted]
    )
    const [cameraListOpen, setCameraListOpen] = useState(false)
    const cameraListButtonRef = useRef<HTMLButtonElement | null>(null)
    const { hideCameraControls } = useLockingContext()

    if (hideCameraControls) {
        return <></>
    }

    const openCameraList = () => {
        if (!cameraListRequest.data) {
            return
        }
        setCameraListOpen(true)
    }

    return <Stack
        direction="row"
        sx={{
            width: "100%",
            height: "17%",
            justifyContent: "center",
            alignItems: "center",
            ...sx
        }}
    >
        <ControlButton onClick={onFlip}>
            <FlipIcon />
        </ControlButton>
        <ControlButton ref={cameraListButtonRef} onClick={openCameraList}>
            <CameraswitchIcon />
        </ControlButton>
        <CameraListMenu
            anchorEl={cameraListButtonRef.current}
            open={cameraListOpen}
            onClose={() => setCameraListOpen(false)}
            cameraId={cameraId}
            onChangeCameraId={onChangeCameraId}
            cameraList={cameraListRequest.data ?? []}
        />
    </Stack>
}

const ControlButton = forwardRef<HTMLButtonElement, IconButtonProps>(({
    sx,
    ...props
}, ref) => {
    return <IconButton
        ref={ref}
        size="small"
        sx={{
            color: "white",
            width: "12%",
            maxWidth: 64,
            margin: "0 5%",
            "& .MuiSvgIcon-root": {
                width: "100%",
                height: "100%",
            },
            ...sx
        }}
        {...props}
    />
})