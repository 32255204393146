import { EventDetails } from "@marketpartner/backend-api"
import { createContext, FC, ReactNode, useContext } from "react"
import { backend } from "src/common/backend"
import { LoadingSpinner } from "src/common/LoadingSpinner"
import { useLoginContext } from "src/login/login-context"

export type EventContext = EventDetails & {
    clientId: string
}

const context = createContext<EventContext>(undefined!)

export const useEvent = () => useContext(context)

export type EventContextProviderProps = {
    children: ReactNode
}

export const EventContextProvider: FC<EventContextProviderProps> = ({
    children,
}) => {
    const { authorization } = useLoginContext()
    const clientId = authorization!.singleClientId!
    const eventId = authorization!.singleEventId!
    const eventRequest = backend.events.useGetEvent([clientId, eventId], {
        refetchInterval: 60_000,
    })

    if (!eventRequest.data) {
        return <LoadingSpinner />
    }

    return <context.Provider
        children={children}
        value={{
            ...eventRequest.data as EventDetails,
            clientId,
        }}
    />
}