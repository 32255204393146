import { ListItemText, Menu, MenuItem, MenuProps } from "@mui/material"
import QrScanner from "qr-scanner"
import { FC } from "react"

export type CameraListMenuProps = Omit<MenuProps, "onClose"> & {
    cameraList: QrScanner.Camera[]
    cameraId: string
    onChangeCameraId: (id: string) => unknown
    onClose: () => unknown
}

export const CameraListMenu: FC<CameraListMenuProps> = ({
    cameraList,
    cameraId,
    onChangeCameraId,
    onClose,
    ...props
}) => {
    const handleSelect = (id: string) => {
        onChangeCameraId(id)
        onClose()
    }

    return <Menu onClose={onClose} {...props}>
        {cameraList.map(camera => <MenuItem
            key={camera.id}
            onClick={() => handleSelect(camera.id)}
        >
            <ListItemText>
                {camera.label}
            </ListItemText>
        </MenuItem>)}
    </Menu>
}
