import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'

const autoUpdateFrequencySeconds = 60

export type AppUpdates = {
    isUpdateAvailable: boolean
    updateApp: () => unknown
}

const context = createContext<AppUpdates>(undefined!)

export const useAppUpdates = () => useContext(context)

export type AppUpdateProviderProps = {
    children: ReactNode
}

export const AppUpdateProvider: FC<AppUpdateProviderProps> = ({
    children
}) => {
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)

    // Check for updates periodically
    useEffect(() => {
        if (!('serviceWorker' in navigator)) {
            console.log("Service workers not supported. Auto-update disabled.")
            return
        }

        const timer = setInterval(async () => {
            console.log("Checking for updates")
            const serviceWorkers = await window.navigator.serviceWorker.getRegistrations()
            console.log(`Checking ${serviceWorkers.length} workers`)
            await Promise.all(serviceWorkers.map(worker => worker.update()))
            console.log("Update check complete")
        }, autoUpdateFrequencySeconds * 1000)

        return () => clearTimeout(timer)
    })

    // Listen for when update installation completes
    useEffect(() => {
        serviceWorkerRegistration.register({
            onUpdate: registration => {
                console.log("App update installed. Ready for refresh")
                setWaitingWorker(registration.waiting)
            }
        })
    }, [])

    // Refresh with installed update
    const updateApp = useCallback(() => {
        if (waitingWorker) {
            waitingWorker.postMessage({ type: 'SKIP_WAITING' })
            window.location.reload()
        }
    }, [waitingWorker])

    return <context.Provider children={children} value={{
        isUpdateAvailable: Boolean(waitingWorker),
        updateApp,
    }} />
}
