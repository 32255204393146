import { IconButton, IconButtonProps } from "@mui/material";
import { forwardRef } from "react";

export type ResponsiveIconButtonProps = IconButtonProps

export const ResponsiveIconButton = forwardRef<HTMLButtonElement, ResponsiveIconButtonProps>(({
    sx,
    ...props
}, ref) => {
    return <IconButton ref={ref} {...props} sx={{
        "& .MuiSvgIcon-root": {
            fontSize: {
                xs: "x-large",
                sm: "xx-large",
            }
        },
        ...sx,
    }}/>
})