import SettingsIcon from "@mui/icons-material/Settings"
import { IconButtonProps } from "@mui/material"
import { FC, useState } from "react"
import { ResponsiveIconButton } from "src/common/ResponsiveIconButton"
import { useLockingContext } from "src/locking/locking-context"
import { SettingsDialog } from "src/settings/SettingsDialog"


export type SettingsButtonProps = IconButtonProps

export const SettingsButton: FC<SettingsButtonProps> = ({
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const { hideSettings } = useLockingContext()

    if (hideSettings) {
        return <></>
    }

    return <>
        <ResponsiveIconButton
            onClick={() => setIsOpen(open => !open)}
            {...props}
        >
            <SettingsIcon />
        </ResponsiveIconButton>
        <SettingsDialog open={isOpen} onClose={() => setIsOpen(false)} />
    </>
}