import { Slider, Stack, SxProps, Typography } from "@mui/material"
import { FC } from "react"
import { LockLevel } from "src/locking/LockLevel"
import { useScanningConfiguration } from "src/settings/scanning-configuration-context"


export type LockSettingsProps = {
    lockLevel: LockLevel
    onChangeLockLevel: (lockLevel: LockLevel) => void
    sx?: SxProps
}

const labels: Record<LockLevel, string> = {
    Open: "Unlocked",
    HideSettings: "Hide settings",
    HideUnscannedRegistrations: "Only list scanned registrations",
    HideRegistrationList: "Hide registration list",
    HideCameraControls: "Hide camera controls",
}

export const LockSettings: FC<LockSettingsProps> = ({
    lockLevel,
    onChangeLockLevel,
    sx,
}) => {
    const lockLevels = Object.values(LockLevel)
    const lockLevelIdx = lockLevels.indexOf(lockLevel)

    const onChangeIdx = (_event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            onChangeLockLevel(lockLevels[newValue])
        }
    }

    return <Stack spacing={2} sx={{
        ...sx,
        mb: 1,
    }}>
        <UnlockCode />
        <Slider
            min={0}
            max={lockLevels.length - 1}
            value={lockLevelIdx}
            onChange={onChangeIdx}
            step={1}
            marks={lockLevels.map((level, idx) => ({
                value: idx,
                label: labels[level],
            }))}
            orientation="vertical"
            sx={{
                minHeight: lockLevels.length * 24,
            }}
        />
    </Stack>
}

const UnlockCode: FC = () => {
    const { unlockCode } = useScanningConfiguration()
    return <Typography>
        Unlock code: <b>{unlockCode}</b>
    </Typography>
}
