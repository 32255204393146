import { Registration, ScanningConfiguration } from "@marketpartner/backend-api"


export const matchRegistrationQrCode = (
    qrData: string,
    { customTokenField }: ScanningConfiguration,
    registrations: Registration[],
): Registration | undefined => {
    let tokenValue: string

    if (customTokenField) {
        tokenValue = qrData.trim()

        if (!tokenValue) {
            throw new Error("Empty QR code")
        }
    } else {
        const match = qrData.match(/^\{([a-z0-9]{16})\}$/)

        if (!match) {
            throw new Error(`Unexpected QR code format: ${qrData}`)
        }

        tokenValue = match[1]
    }

    return registrations.find(registration =>
        customTokenField ?
            registration.fields[customTokenField] === tokenValue :
            registration.accessToken === tokenValue
    )
}