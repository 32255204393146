import { fullName } from "@marketpartner/mp-common"
import { Close } from "@mui/icons-material"
import Check from "@mui/icons-material/Check"
import { Box, Button, DialogTitle, Theme, useTheme } from "@mui/material"
import { FC } from "react"
import { SelectedToken } from "src/dispatch/useSelectionCallback"

export type InteractionHeaderProps = {
    selection: SelectedToken
    close: () => void
}

export const InteractionHeader: FC<InteractionHeaderProps> = ({
    selection,
    close,
}) => {
    const theme = useTheme()

    const {
        backgroundColor,
        color,
        headerText,
        headerIcon,
    } = getHeaderStyle(theme, selection!)

    return <DialogTitle
        sx={{
            backgroundColor,
            color,
            display: "flex",
            alignItems: "center",
            gap: 1,
        }}
    >
        {headerIcon}
        {headerText}
        <Box sx={{ mx: "auto" }} />
        <Button
            onClick={close}
            endIcon={<Close />}
            sx={{
                color,
                fontSize: "large"
            }}
        >
            Cancel
        </Button>
    </DialogTitle>
}

const getHeaderStyle = (theme: Theme, { registration }: SelectedToken) => {
    if (!registration) {
        return {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
            headerText: "Unknown user",
            headerIcon: <Close />,
        }
    }
    return {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        headerText: fullName(registration),
        headerIcon: <Check />,
    }
}