import { ActivitySource, ActivityType, getActivityId, UploadScanResultBody } from "@marketpartner/backend-api"
import { DateTime } from "luxon"
import { createContext, FC, ReactNode, useCallback, useContext, useMemo } from "react"
import { backend } from "src/common/backend"
import { BackgroundUpdateItem } from "src/common/background-update/background-update-storage"
import { useBackgroundUpdate } from "src/common/background-update/useBackgroundUpdate"
import { useEvent } from "src/common/event-context"
import { useEventStorage } from "src/common/useEventStorage"
import { SelectedToken, useSelectionCallback } from "src/dispatch/useSelectionCallback"
import { useScannerSettings } from "src/settings/scanner-settings-context"
import { v4 as uuidV4 } from 'uuid'

export type AttendanceTracker = {
    scannedTokens: Set<string>
    queuedScans: BackgroundUpdateItem<UploadScanResultBody>[]
}

const context = createContext<AttendanceTracker>(undefined!)

export const useAttendanceTracker = () => useContext(context)

export type AttendanceTrackerContextProps = {
    children: ReactNode
}

export const AttendanceTrackerContext: FC<AttendanceTrackerContextProps> = ({
    children
}) => {
    const event = useEvent()
    const settings = useScannerSettings()
    const sessionId = settings.sessionId ?? "event"
    const [locallyScannedTokens, setLocallyScannedTokens] = useEventStorage<string[]>(`${sessionId}:locallyScannedTokens`, [])
    const statsQuery = backend.activities.useGetActivityUsage([
        event.clientId,
        event.id,
        ActivityType.action,
        getActivityId("Attend", sessionId, ActivitySource.physical)
    ], {
        refetchInterval: 5_000
    })
    const scannedTokens = useMemo(
        () => new Set([
            ...locallyScannedTokens,
            ...statsQuery.data?.map(it => it.registration?.accessToken ?? "") ?? []
        ].filter(Boolean)),
        [locallyScannedTokens, statsQuery.data]
    )
    const uploadMutation = backend.scanning.useUploadScanResults()

    const backgroundUpload = useBackgroundUpdate(
        `event:${event.clientId}:${event.id}:attendance:v2`,
        (result: UploadScanResultBody) => uploadMutation.mutateAsync([event.clientId, event.id, result]),
    )

    const onSelect = useCallback(({ token, isRepeatScan }: SelectedToken) => {
        if (isRepeatScan) {
            return
        }
        setLocallyScannedTokens(tokens => {
            const tokenSet = new Set(tokens)
            tokenSet.add(token)
            return Array.from(tokenSet)
        })
        backgroundUpload.enqueue({
            scanResultId: uuidV4(),
            scannerId: settings.id,
            token,
            time: DateTime.utc(),
            sessionId: settings.sessionId,
        })
    }, [backgroundUpload, settings, setLocallyScannedTokens])

    useSelectionCallback(onSelect)

    return <context.Provider children={children} value={{
        queuedScans: backgroundUpload.items,
        scannedTokens,
    }} />
}
