import { Lock } from "@mui/icons-material"
import { Box, Button, Dialog, IconButton, SxProps } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useLockingContext } from "src/locking/locking-context"
import { ConfirmLockCodeDialog } from "src/locking/ConfirmLockCodeDialog"
import { ResponsiveDialog } from "src/common/ResponsiveDialog"


export type LockControlProps = {
    sx?: SxProps
}

export const LockControl: FC<LockControlProps> = ({
    sx,
}) => {
    const {
        isLocked,
        lockingEnabled,
    } = useLockingContext()

    return <Box sx={{
        position: "relative",
        ...sx,
    }}>
        {lockingEnabled && !isLocked && <LockButton />}
        {isLocked && <UnlockButton />}
    </Box >
}

const LockButton: FC = () => {
    const [open, setOpen] = useState(false)
    const { setIsLocked } = useLockingContext()

    return <>
        <Button
            color="error"
            startIcon={<Lock />}
            onClick={() => setOpen(true)}
            sx={{
                position: "absolute",
                left: 0,
                bottom: 0,
            }}
            size="large"
            variant="contained"
        >
            Lock
        </Button>
        <ResponsiveDialog open={open}>
            <ConfirmLockCodeDialog
                onClose={() => setOpen(false)}
                onConfirm={() => setIsLocked(true)}
                buttonText="Lock"
            />
        </ResponsiveDialog>
    </>
}

const requiredClicks = 3
const clickDelayThresholdMs = 500

const UnlockButton: FC = () => {
    const [open, setOpen] = useState(false)
    const [clickCount, setClickCount] = useState(0)
    const { setIsLocked } = useLockingContext()

    useEffect(() => {
        const timer = setTimeout(
            () => setClickCount(0),
            clickDelayThresholdMs,
        )
        return () => clearTimeout(timer)
    }, [clickCount])

    const handleClick = () => {
        if (clickCount >= requiredClicks - 1) {
            setOpen(true)
            setClickCount(0)
        } else {
            setClickCount(count => count + 1)
        }
    }

    return <>
        <IconButton
            onClick={handleClick}
            sx={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "20vw",
                height: "20vh",
            }}
        />
        <Dialog open={open}>
            <ConfirmLockCodeDialog
                onClose={() => setOpen(false)}
                onConfirm={() => setIsLocked(false)}
                buttonText="Unlock"
            />
        </Dialog>
    </>
}