

export function nChunks<T>(items: T[], chunkCount: number): T[][] {
    const chunkSize = Math.ceil(items.length / chunkCount)

    const chunks = [] as T[][]
    for (let i = 0; i < items.length; i += chunkSize) {
        const chunk = items.slice(i, i + chunkSize)
        chunks.push(chunk)
    }
    return chunks
}