import { FormControlLabelProps, FormControlLabel, Switch } from "@mui/material"
import { FC } from "react"

export type SimpleToggleProps = Omit<FormControlLabelProps, "onChange" | "control"> & {
    checked: boolean
    onChange: (checked: boolean) => unknown
}

export const SimpleToggle: FC<SimpleToggleProps> = ({
    checked,
    onChange,
    ...props
}) => {
    return <FormControlLabel
        {...props}
        control={<Switch
            checked={checked}
            onChange={() => onChange(!checked)}
        />}
        sx={{
            width: "fit-content",
            ...props.sx,
        }}
    />
}