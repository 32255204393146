import { Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { FC, FormEvent, useEffect, useRef, useState } from "react"
import { useScanningConfiguration } from "src/settings/scanning-configuration-context"


type ConfirmLockCodeDialogProps = {
    onClose: () => void
    onConfirm: () => void
    buttonText: string
}

export const ConfirmLockCodeDialog: FC<ConfirmLockCodeDialogProps> = ({
    onClose,
    onConfirm,
    buttonText,
}) => {
    const { unlockCode } = useScanningConfiguration()
    const [code, setCode] = useState("")
    const inputRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const timer = setTimeout(() => inputRef.current?.focus(), 100)
        return () => clearTimeout(timer)
    }, [])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (code !== unlockCode) {
            return
        }
        onConfirm()
        onClose()
        return false
    }

    return <>
        <DialogTitle>Confirm unlock code</DialogTitle>
        <DialogContent>
            <form id="confirm-form" onSubmit={handleSubmit}>
                <TextField
                    label="Enter code"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    inputRef={inputRef}
                />
            </form>
        </DialogContent>
        <DialogActions>
            <Button color="inherit" onClick={onClose}>Cancel</Button>
            <Button variant="contained" type="submit" form="confirm-form">{buttonText}</Button>
        </DialogActions>
    </>
}