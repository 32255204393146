import { AppBar, AppBarProps, Box, Toolbar, Typography } from "@mui/material"
import { FC } from "react"
import { AttendanceCount } from "src/attendance/AttendanceCount"
import { useEvent } from "src/common/event-context"
import { PrinterStatusIcon } from "src/printing/PrinterStatusIcon"
import { RegistrationSearchButton } from "src/registrations/RegistrationSearchButton"

export type HeaderBarProps = AppBarProps


export const HeaderBar: FC<HeaderBarProps> = ({
    ...props
}) => {
    const event = useEvent()

    return <AppBar {...props}>
        <Toolbar>
            <Typography variant="h6" component="h1">
                {event.name}
            </Typography>
            <Box sx={{ flex: "1" }} />
            <AttendanceCount sx={{ alignSelf: "center" }}/>
            <PrinterStatusIcon />
            <RegistrationSearchButton sx={{ ml: 2 }}/>
        </Toolbar>
    </AppBar>
}
