import { Form, FormType } from "@marketpartner/backend-api"
import { filterType } from "@marketpartner/mp-common"
import { LoadedSelector, LoadedSelectorProps } from "@marketpartner/mp-common-mui"
import { Link } from "@mui/material"
import { FC, MouseEventHandler, useState } from "react"
import { backend } from "src/common/backend"
import { useEvent } from "src/common/event-context"
import { FormPreviewDialog } from "src/forms/FormPreviewDialog"


export type FormSelectProps = Partial<LoadedSelectorProps<number, Form, Form[]>> & {
    formId: number | null
    onChange: (formId: number | null) => unknown
}

export const FormSelect: FC<FormSelectProps> = ({
    formId,
    onChange,
    ...props
}) => {
    const event = useEvent()
    const formsQuery = backend.forms.useGetAll([event.clientId, event.id], {
        select: forms => filterType(forms, FormType.Standard as const)
    })

    return <LoadedSelector
        label="Form"
        {...props}
        fetchRequest={formsQuery}
        selectedId={formId}
        onSelectId={onChange}
        helperContent={() => formId && <FormPreviewLink formId={formId} />}
    />
}

export const FormPreviewLink: FC<{
    formId: number
}> = ({ formId }) => {
    const [previewOpen, setPreviewOpen] = useState(false)

    const handleClick: MouseEventHandler = (e) => {
        e.preventDefault()
        setPreviewOpen(true)
    }

    return <>
        <Link onClick={handleClick} href="#">
            Preview form
        </Link>
        <FormPreviewDialog
            formId={formId}
            open={previewOpen}
            onClose={() => setPreviewOpen(false)}
        />
    </>
}