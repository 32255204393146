import { createContext, FC, ReactNode, useContext } from "react"
import { useEventStorage } from "src/common/useEventStorage"
import { LockLevel } from "src/locking/LockLevel"
import { useScannerSettings } from "src/settings/scanner-settings-context"


export type LockingContext = {
    isLocked: boolean
    setIsLocked: (isLocked: boolean) => void

    lockingEnabled: boolean
    hideSettings: boolean
    hideUnscannedRegistrations: boolean
    hideRegistrationList: boolean
    hideCameraControls: boolean
}

const context = createContext<LockingContext>(undefined!)

export const useLockingContext = () => useContext(context)

export type LockingContextProviderProps = {
    children: ReactNode
}

export const LockingContextProvider: FC<LockingContextProviderProps> = ({
    children
}) => {
    const [isLocked, setIsLocked] = useEventStorage("is-locked", false)
    const { lockLevel } = useScannerSettings()

    function shouldHide(action: LockLevel) {
        const lockLevels = Object.values(LockLevel)
        const configuredLockLevelIdx = lockLevels.indexOf(lockLevel)
        return isLocked && configuredLockLevelIdx >= lockLevels.indexOf(action)
    }

    return <context.Provider children={children} value={{
        isLocked,
        setIsLocked,
        lockingEnabled: lockLevel !== LockLevel.Open,

        hideSettings: shouldHide(LockLevel.HideSettings),
        hideUnscannedRegistrations: shouldHide(LockLevel.HideUnscannedRegistrations),
        hideRegistrationList: shouldHide(LockLevel.HideRegistrationList),
        hideCameraControls: shouldHide(LockLevel.HideCameraControls),
    }} />
}