import { Box, SxProps } from "@mui/material"
import QrScanner from "qr-scanner"
import { FC, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { zIndex } from "src/common/zIndex"
import { CameraControls } from "src/scanning/CameraControls"

export type QrCodeScannerProps = {
    onScan: (data: string) => unknown
    overlays?: Record<string, ReactNode | undefined | null | false>
    sx?: SxProps
}

/**
 * This is a generic QR code reader, with options for mirroring 
 * and selecting the camera.
 * It also allows displaying any number of overlays on top
 * of the video.
 */
export const QrCodeScanner: FC<QrCodeScannerProps> = ({
    onScan,
    sx,
    overlays = {},
}) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const scannerRef = useRef<QrScanner | null>(null)
    const maskOverlay = useRef<HTMLDivElement>(null)
    const [mirror, setMirror] = useState(false)
    const [text, setText] = useState<string | undefined>()
    const [cameraId, setCameraId] = useState("")
    const [cameraIsStarted, setCameraIsStarted] = useState(false)

    useEffect(() => {
        if (text) {
            onScan(text)
            setText(undefined)
        }
    }, [text, onScan])

    useLayoutEffect(() => {
        const scanner = scannerRef.current = new QrScanner(
            videoRef.current!,
            result => setText(result.data),
            {
                returnDetailedScanResult: true,
                highlightCodeOutline: true,
                overlay: maskOverlay.current ?? undefined
            }
        )
        scanner.start().then(() => {
            setCameraIsStarted(true)
        })
        return () => {
            scanner.destroy()
            scannerRef.current = null
        }
    }, [])

    const handleCameraChange = useCallback((cameraId: string) => {
        setCameraId(cameraId)
        scannerRef.current?.setCamera(cameraId)
    }, [])

    return <Box
        sx={{
            position: "relative",
            display: "flex",
            overflow: "hidden",
            ...sx,
        }}
    >
        <video ref={videoRef} style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: mirror ? "scaleX(-1)" : "scaleX(1)",
        }} />
        <Box ref={maskOverlay} sx={{
            borderRadius: "5%",
            outline: "rgba(0,0,0,0.5) solid 50vmax",
        }} />
        <CameraControls
            onFlip={() => setMirror(!mirror)}
            cameraIsStarted={cameraIsStarted}
            cameraId={cameraId}
            onChangeCameraId={handleCameraChange}
            sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: zIndex.videoControls,
            }}
        />
        {Object.entries(overlays)
            .filter(([_id, overlay]) => Boolean(overlay))
            .map(([id, overlay]) =>
                <Box
                    id={id}
                    key={id}
                    sx={{
                        position: "absolute",
                        inset: "0",
                        zIndex: zIndex.videoOverlay
                    }}
                >
                    {overlay}
                </Box>
            )}
    </Box>
}