import { FormData, ScannerFormResponseBody } from "@marketpartner/backend-api"
import { FC, ReactNode, createContext, useCallback, useContext } from "react"
import { backend } from "src/common/backend"
import { BackgroundUpdateItem } from "src/common/background-update/background-update-storage"
import { useBackgroundUpdate } from "src/common/background-update/useBackgroundUpdate"
import { useEvent } from "src/common/event-context"
import { useScannerSettings } from "src/settings/scanner-settings-context"

export type QueuedFormResponse = ScannerFormResponseBody & {
    formId: number
}

export type FormContext = {
    submit: (token: string, formData: FormData) => void
    queuedResponses: BackgroundUpdateItem<QueuedFormResponse>[]
}

const context = createContext<FormContext>(undefined!)

export const useFormContext = () => useContext(context)

export type FormContextProviderProps = {
    children: ReactNode
}

export const FormContextProvider: FC<FormContextProviderProps> = ({
    children
}) => {
    const event = useEvent()
    const { id, sessionId, formId } = useScannerSettings()

    // Ensure forms are preloaded
    backend.forms.useGetAll([event.clientId, event.id])
    backend.forms.useGet([event.clientId, event.id, formId!], {
        enabled: Boolean(formId)
    })

    const uploadResponseMutation = backend.scanning.useUploadFormResponses()

    const backgroundUpload = useBackgroundUpdate(
        `event:${event.clientId}:${event.id}:responses`,
        (response: QueuedFormResponse) => uploadResponseMutation.mutateAsync([event.clientId, event.id, response.formId, response]),
    )

    const enqueueResponse = useCallback(
        (token: string, formData: FormData) => {
            if (!formId) {
                console.error(`Can't submit form response without a form configured`)
                return
            }
            backgroundUpload.enqueue({
                scannerId: id,
                sessionName: sessionId,
                formId,
                token,
                formData,
            })
        },
        [formId, id, backgroundUpload, sessionId]
    )

    return <context.Provider children={children} value={{
        submit: enqueueResponse,
        queuedResponses: backgroundUpload.items,
    }} />
}