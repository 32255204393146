import { Box, SxProps } from "@mui/system"
import { FC } from "react"
import { useSelectionDispatch } from "src/dispatch/selection-dispatch-context"
import { useInteractionOverlay } from "src/interaction/interaction-overlay-context"
import { InteractionOverlay } from "src/interaction/InteractionOverlay"
import { RegistrationQrCodeScanner } from "src/scanning/RegistrationQrCodeScanner"

export type InteractiveQrCodeScannerProps = {
    sx?: SxProps
}

export const InteractiveQrCodeScanner: FC<InteractiveQrCodeScannerProps> = ({
    sx
}) => {
    const selectionDispatch = useSelectionDispatch()
    const overlay = useInteractionOverlay()

    return <Box sx={{
        overflow: "hidden",
        ...sx
    }}>
        <RegistrationQrCodeScanner
            enabled={!overlay.isOpen}
            onScan={selectionDispatch.onScanRegistration}
            onScanUnknown={selectionDispatch.onScanToken}
            overlays={{
                "interaction": overlay.isOpen && <InteractionOverlay />
            }}
            sx={{
                width: "100%",
                height: "100%",
            }}
        />
    </Box>
}
