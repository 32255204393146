import { Registration } from "@marketpartner/backend-api"
import { useIsMounted } from "@marketpartner/mp-common-react"
import { Box, SxProps, Typography } from "@mui/material"
import { FC, ReactNode, useCallback, useRef, useState } from "react"
import { useRegistrations } from "src/registrations/registrations-context"
import { matchRegistrationQrCode } from "src/scanning/match-registration-qr-code"
import { useScanningConfiguration } from "src/settings/scanning-configuration-context"
import { QrCodeScanner } from "./QrCodeScanner"

export type RegistrationQrCodeScannerProps = {
    enabled: boolean
    onScan: (registration: Registration) => unknown
    onScanUnknown: (token: string) => unknown
    overlays?: Record<string, ReactNode | undefined | null | false>
    sx?: SxProps
}

const invalidNotificationTimeoutMs = 500

/**
 * Wrapper around QrCodeScanner. QR data callbacks are replaced with more
 * powerful onScan and onScanUnknown callbacks, which return the matched 
 * registration record (or token if no match was found).
 * 
 * Requires RegistrationContext to be set up so the registration data 
 * can be searched for a match.
 * 
 * Displays an "Invalid QR code" message if the QR code isn't in the 
 * expected format.
 * 
 * Extraction of the registration token from the QR code can be customized
 * to support different QR formats in the future if required.
 * 
 * Also supports displaying arbitrary video overlays
 * (as per QrCodeScanner).
 */
export const RegistrationQrCodeScanner: FC<RegistrationQrCodeScannerProps> = ({
    enabled,
    onScan,
    onScanUnknown,
    overlays = {},
    sx,
}) => {
    const [isInvalid, setIsInvalid] = useState(false)
    const invalidTimer = useRef<NodeJS.Timeout | null>()
    const registrations = useRegistrations()
    const configuration = useScanningConfiguration()
    const isMounted = useIsMounted()

    const triggerInvalid = useCallback(() => {
        setIsInvalid(true)
        if (invalidTimer.current) {
            clearTimeout(invalidTimer.current)
        }
        invalidTimer.current = setTimeout(() => {
            if (isMounted.now) {
                setIsInvalid(false)
            }
        }, invalidNotificationTimeoutMs)
    }, [isMounted])

    const handleScan = useCallback((data: string) => {
        if (!enabled) {
            return
        }

        try {
            const registration = matchRegistrationQrCode(data, configuration, registrations.registrations)
            if (registration) {
                onScan(registration)
            } else {
                onScanUnknown(data)
            }
        } catch (error) {
            console.error(error)
            triggerInvalid()
        }

    }, [enabled, triggerInvalid, registrations, onScan, onScanUnknown, configuration])

    return <Box sx={{
        position: "relative",
        ...sx,
    }}>
        <QrCodeScanner
            sx={{
                width: "100%",
                height: "100%",
            }}
            onScan={handleScan}
            overlays={{
                "invalid-code": isInvalid && <InvalidCodeOverlay />,
                ...overlays
            }}
        />
    </Box>
}

const InvalidCodeOverlay: FC = () => {
    return <Typography color="error" sx={{
        position: "absolute",
        top: "3%",
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: "7vmin",
        fontWeight: "bold",
    }}>
        Invalid QR code
    </Typography>
}