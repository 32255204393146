import { Registration } from "@marketpartner/backend-api"
import { SearchOptions, searchProperties } from "@marketpartner/mp-common"
import { SearchBox } from "@marketpartner/mp-common-mui"
import { useDebounce } from "@marketpartner/mp-common-react"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { Box, Button, DialogActions, DialogContent, DialogProps, DialogTitle, Stack } from "@mui/material"
import { FC, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useAttendanceTracker } from "src/attendance/attendance-tracker-context"
import { ResponsiveDialog } from "src/common/ResponsiveDialog"
import { ResponsiveIconButton } from "src/common/ResponsiveIconButton"
import { useSelectionDispatch } from "src/dispatch/selection-dispatch-context"
import { useLockingContext } from "src/locking/locking-context"
import { useRegistrations } from "src/registrations/registrations-context"
import { getRegistrationTreePath, RegistrationsGrid } from "src/registrations/RegistrationsGrid"

const searchFields = (reg: Registration) => [
    reg.title,
    reg.firstName,
    reg.lastName,
    reg.email,
    reg.fields["company"],
]
const searchOptions: SearchOptions<Registration> = {
    treeOptions: {
        getTreePath: getRegistrationTreePath,
        alwaysReturnChildrenOfMatch: true,
    }
}

export type RegistrationSearchDialogProps = Omit<DialogProps, "onClose"> & {
    onClose: () => unknown
}

export const RegistrationSearchDialog: FC<RegistrationSearchDialogProps> = ({
    onClose,
    ...props
}) => {

    return <ResponsiveDialog
        onClose={() => onClose()}
        maxWidth="sm"
        fullWidth
        {...props}
        PaperProps={{
            sx: {
                height: "100%"
            }
        }}
    >
        <RegistrationsSearchDialogContent onClose={onClose} />
    </ResponsiveDialog>
}

const RegistrationsSearchDialogContent: FC<{
    onClose: () => unknown
}> = ({ onClose }) => {
    const { registrations } = useRegistrations()
    const [selectedRegistration, setSelectedRegistration] = useState<Registration | undefined>()
    const [searchText, setSearchText] = useState("")
    const debouncedSearch = useDebounce(searchText)
    const searchInputRef = useRef<HTMLDivElement>()

    const { onSelectRegistration } = useSelectionDispatch()
    const { hideUnscannedRegistrations } = useLockingContext()
    const { scannedTokens } = useAttendanceTracker()

    const filteredRegistrations = useMemo(
        () => searchProperties(registrations, debouncedSearch, searchFields, searchOptions)
            .filter(reg => !hideUnscannedRegistrations || scannedTokens.has(reg.accessToken)),
        [registrations, debouncedSearch, hideUnscannedRegistrations, scannedTokens]
    )

    useLayoutEffect(() => {
        searchInputRef.current?.focus()
    }, [])

    const handleSubmit = () => {
        if (selectedRegistration) {
            onSelectRegistration(selectedRegistration)
        }
        onClose()
    }

    return <>
        <Stack flexDirection="row" sx={{
            alignItems: "center",
        }}>
            <DialogTitle>
                Search registrations
            </DialogTitle>
            <Box sx={{ flex: "1" }} />
            <ResponsiveIconButton onClick={() => onClose()} sx={{ m: 1 }}>
                <CloseIcon />
            </ResponsiveIconButton>
        </Stack>
        <SearchBox
            searchText={searchText}
            setSearchText={setSearchText}
            showIcon
            sx={{
                maxWidth: 300,
                mx: 3,
            }}
        />
        <DialogContent>
            <RegistrationsGrid
                registrations={filteredRegistrations}
                selectedRegistration={selectedRegistration}
                onSelectRegistration={setSelectedRegistration}
                disableTreeData={hideUnscannedRegistrations}
                sx={useMemo(() => ({
                    height: "100%"
                }), [])}
            />
        </DialogContent>
        <DialogActions>
            <Button
                startIcon={<CheckIcon />}
                disabled={!selectedRegistration}
                onClick={handleSubmit}
            >
                Mark attended
            </Button>
        </DialogActions>
    </>
}
