import { CheckCircle } from "@mui/icons-material"
import { SxProps } from "@mui/material"
import { FC } from "react"
import { useAttendanceTracker } from "src/attendance/attendance-tracker-context"


export type ScannedIndicatorProps = {
    token: string
    sx?: SxProps
}

export const ScannedIndicator: FC<ScannedIndicatorProps> = ({
    token,
    sx,
}) => {
    const { scannedTokens } = useAttendanceTracker()

    return <CheckCircle 
        color="success"
        sx={{
            visibility: scannedTokens.has(token) ? "visible" : "hidden",
            ...sx,
        }}
    />
}