import { Dialog } from "@mui/material"
import { FC, ReactNode } from "react"
import { FormUi } from "src/forms/FormUi"
import { ConfirmSelection } from "src/interaction/ConfirmSelection"
import { FullscreenRepeatScanOverlay } from "src/interaction/FullscreenRepeatScanOverlay"
import { FullscreenUnknownTokenOverlay } from "src/interaction/FullscreenUnknownTokenOverlay"
import { FullscreenVerifiedOverlay } from "src/interaction/FullscreenVerifiedOverlay"
import { useInteractionOverlay } from "src/interaction/interaction-overlay-context"
import { InteractionHeader } from "src/interaction/InteractionHeader"
import { useScannerSettings } from "src/settings/scanner-settings-context"


export const InteractionOverlay: FC = () => {
    const { isOpen, close } = useInteractionOverlay()

    return <Dialog
        open={isOpen}
        onClose={close}
        fullScreen
    >
        <DialogContent />
    </Dialog>
}

export const DialogContent: FC = () => {
    const { selection, pendingSelection, close, } = useInteractionOverlay()
    const { formId } = useScannerSettings()

    if (pendingSelection) {
        return <ConfirmSelection />
    }

    if (!selection) {
        return <></>
    }

    if (selection.isRepeatScan) {
        return <FullscreenRepeatScanOverlay />
    }

    let interactionContent: ReactNode
    if (formId) {
        interactionContent = <FormUi />
    }

    if (interactionContent) {
        return <>
            <InteractionHeader selection={selection} close={close} />
            {interactionContent}
        </>
    }

    if (selection.registration) {
        return <FullscreenVerifiedOverlay registration={selection.registration} />
    }
    return <FullscreenUnknownTokenOverlay />
}