import { Registration, RegistrationType } from "@marketpartner/backend-api"
import { useUpdate } from "@marketpartner/mp-common-react"
import { useSnackbar } from "notistack"
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { SelectedToken, useSelectionCallback } from "src/dispatch/useSelectionCallback"
import { BrowserPrinter, BrowserPrinterFactory } from "src/printing/BrowserPrinter"
import { formatBadge } from "src/printing/format-badge"
import { useRegistrations } from "src/registrations/registrations-context"
import { useScannerSettings } from "src/settings/scanner-settings-context"
import { useScanningConfiguration } from "src/settings/scanning-configuration-context"

export type PrinterStatus = "disabled" | "no-format" | "connecting" | "error" | "ready" | "printing"

export type PrintingContext = {
    status: PrinterStatus
    reconnect: () => void
}

const context = createContext<PrintingContext>(undefined!)

export const usePrintingContext = () => useContext(context)

export type PrintingContextProviderProps = {
    children: ReactNode
}

const printerFactory = BrowserPrinterFactory

export const PrintingContextProvider: FC<PrintingContextProviderProps> = ({
    children
}) => {
    const [printer, setPrinter] = useState<BrowserPrinter | undefined>()
    const [connectionError, setConnectionError] = useState<any>()
    const configuration = useScanningConfiguration()
    const settings = useScannerSettings()
    const { enqueueSnackbar } = useSnackbar()
    const { categories } = useRegistrations()

    const connectRequest = useUpdate(
        {
            update: () => {
                setConnectionError(undefined)
                setPrinter(undefined)
                return printerFactory.connect()
            },
            onSuccess: setPrinter,
            onError: error => {
                setConnectionError(error || "Unknown/empty error")
                console.error("Error connecting to printer", error)
                enqueueSnackbar("Could not connect to printer", {
                    variant: "error"
                })
            }
        }
    )
    const reconnect = connectRequest.trigger

    const { printCapsNames } = useScannerSettings()

    const printRequest = useUpdate(
        {
            update: async (registration: Registration): Promise<unknown> => {
                if (!printer) {
                    console.warn("Attempted to print while not connected to printer")
                    enqueueSnackbar("Printer not connected", {
                        variant: "error"
                    })
                } else if (!configuration.printFormat) {
                    console.warn("Attempted to print when print format is not configured")
                    enqueueSnackbar("Print format not configured", {
                        variant: "error"
                    })
                } else {
                    const category = registration.type === RegistrationType.Primary ? categories.find(c => c.id === registration.categoryId) : undefined
                    return printer.print(formatBadge(configuration.printFormat, registration, printCapsNames, category))
                }
            },
            onSuccess: (response, ..._args) => console.log(`Print complete`, response),
            onError: error => {
                console.error(`Printing failed`, error)
                enqueueSnackbar("Failed to print badge", {
                    variant: "error"
                })
            }
        }
    )

    useEffect(() => {
        if (settings.printingEnabled) {
            reconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.printingEnabled])

    const onSelect = useCallback(
        ({ registration, isRepeatScan }: SelectedToken) => {
            if (!settings.printingEnabled ||
                !registration ||
                isRepeatScan) {
                return
            }
            printRequest.trigger(registration)
        },
        [settings.printingEnabled, printRequest]
    )

    useSelectionCallback(onSelect)

    let status: PrinterStatus
    if (!settings.printingEnabled) {
        status = "disabled"
    } else if (!configuration.printFormat) {
        status = "no-format"
    } else if (connectionError) {
        status = "error"
    } else if (!printer) {
        status = "connecting"
    } else if (printRequest.isLoading) {
        status = "printing"
    } else {
        status = "ready"
    }

    return <context.Provider children={children} value={{
        status,
        reconnect,
    }} />
}