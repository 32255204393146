import { useEffect, useMemo, useState } from "react"
import { BackgroundUpdateFunction, BackgroundUpdateProcess } from "src/common/background-update/background-update-process"
import { BackgroundUpdateItem, BackgroundUpdateStorage } from "src/common/background-update/background-update-storage"


export type BackgroundUpdate<T> = {
    items: BackgroundUpdateItem<T>[]
    enqueue: (item: T) => unknown
}

export type BackgroundUpdateOptions = {
    updateFrequencySeconds: number
    displayName?: string
}

const defaultBackgroundUpdateOptions: BackgroundUpdateOptions = {
    updateFrequencySeconds: 5
}

/**
 * Stores items locally, and processes them in batches using a timer.
 * Supports retries (e.g. when offline).
 * Note: The update function MUST be wrapped in a useCallback(),
 * otherwise the timer will restart on every re-render.
 */
export const useBackgroundUpdate = <T>(
    localStorageKey: string,
    batchUpdate: BackgroundUpdateFunction<T>,
    options?: Partial<BackgroundUpdateOptions>
): BackgroundUpdate<T> => {
    const completeOptions = {
        ...defaultBackgroundUpdateOptions,
        ...options,
    }
    const [items, setItems] = useState<BackgroundUpdateItem<T>[]>([])
    const storage = useMemo(
        () => new BackgroundUpdateStorage<T>(localStorageKey),
        [localStorageKey]
    )

    useEffect(() => {
        setItems(storage.getItems())
        storage.onChange = setItems
        return () => storage.onChange = undefined
    }, [storage])

    useEffect(() => {
        const updateProcess = new BackgroundUpdateProcess(
            storage,
            completeOptions.updateFrequencySeconds,
            batchUpdate,
        )
        return () => updateProcess.stop()
    }, [storage, completeOptions.updateFrequencySeconds, batchUpdate])

    return useMemo(() => ({
        items,
        enqueue: storage.addItem,
    }), [storage, items])
}

