import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from "react"
import { useSelectionDispatch } from "src/dispatch/selection-dispatch-context"
import { SelectedToken, useSelectionCallback } from "src/dispatch/useSelectionCallback"

export type InteractionOverlayContext = {
    isOpen: boolean
    selection: SelectedToken | undefined
    pendingSelection: SelectedToken | undefined
    close: () => unknown
}

const context = createContext<InteractionOverlayContext>(undefined!)

export const useInteractionOverlay = () => useContext(context)

export type InteractionOverlayContextProviderProps = {
    children: ReactNode
}

/**
 * Central management of display of overlay. This is required so the scanner can be disabled
 * while the overlay is open.
 */
export const InteractionOverlayContextProvider: FC<InteractionOverlayContextProviderProps> = ({
    children
}) => {
    const [selection, setSelection] = useState<SelectedToken>()
    const { pendingSelection, cancelPendingSelection, } = useSelectionDispatch()

    useSelectionCallback(setSelection)

    const close = useCallback(
        () => {
            setSelection(undefined)
            cancelPendingSelection()
        },
        [cancelPendingSelection]
    )

    return <context.Provider
        children={children}
        value={useMemo(() => ({
            isOpen: Boolean(selection || pendingSelection),
            selection,
            pendingSelection,
            close,
        }), [selection, pendingSelection, close])}
    />
}