import { PrinterFactory } from "src/printing/Printer"

export type BrowserPrinter = {
    print: (data: string) => Promise<unknown>
}

export const BrowserPrinterFactory: PrinterFactory = {

    connect: async function(): Promise<BrowserPrinter> {
        return new Promise((resolve, reject) => {
            console.log("Connecting to default printer");

            (window as any).BrowserPrint.getDefaultDevice(
                'printer',
                (printer: any) => {
                    console.log("Checking printer connection")

                    if (!printer) {
                        reject(new Error("Could not find default printer"))
                    } else if (!printer.connection) {
                        reject(new Error("Could not connect to printer"))
                    } else {
                        console.log("Connected to printer")
                        resolve({
                            print: data => sendAsync(printer, data)
                        })
                    }
                },
                reject
            )
        })
    },
}

async function sendAsync(browserPrinter: any, data: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
        console.log("Printing")
        browserPrinter.send(data, resolve, reject)
    })
}