import { Registration, RegistrationApprovalState, RegistrationType } from "@marketpartner/backend-api"
import { nameSortKey } from "@marketpartner/mp-common"
import { Stack, SxProps, Theme, Typography, useMediaQuery } from "@mui/material"
import { DataGridPro, GridColumnVisibilityModel, GridEnrichedColDef, GridInputSelectionModel, GridRowClassNameParams, GridSelectionModel, GRID_TREE_DATA_GROUPING_FIELD } from "@mui/x-data-grid-pro"
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro"
import { FC, useCallback, useMemo } from "react"
import { NameAndEmail } from "src/common/NameAndEmail"
import { ScannedIndicator } from "src/registrations/ScannedIndicator"


export type RegistrationsGridProps = {
    registrations: Registration[]
    selectedRegistration?: Registration
    onSelectRegistration: (registration?: Registration) => void
    disableTreeData: boolean
    sx?: SxProps
}

const getColumnVisibility = (isXs: boolean): GridColumnVisibilityModel => ({
    [GRID_TREE_DATA_GROUPING_FIELD]: false,
    company: !isXs,
})

const buildColumns = (isXs: boolean): GridEnrichedColDef<Registration>[] => [
    {
        field: "attended",
        headerName: "",
        align: "center",
        renderCell: ({ row }) => <ScannedIndicator token={row.accessToken} />,
        width: 50,
        resizable: false,
        sortable: false,
    },
    {
        field: "name",
        headerName: "Name",
        renderCell: ({ row }) => {
            const leftMargin = row.type === RegistrationType.Guest ? 3 : 0
            return <Stack direction="column">
                <NameAndEmail
                    registration={row}
                    status={row.approvalState === RegistrationApprovalState.Approved ? "" : `(${row.approvalState})`}
                    sx={{ ml: leftMargin }} />
                {isXs && <Typography
                    variant="body2"
                    sx={{ ml: leftMargin }}
                >
                    {row.fields.company as string}
                </Typography>}
            </Stack>
        },
        valueGetter: ({ row }) => nameSortKey(row),
        minWidth: 100,
        flex: 8,
    },
    {
        field: "company",
        headerName: "Company",
        valueGetter: ({ row }) => row.fields["company"],
        minWidth: 50,
        flex: 5,
        resizable: false,
    },
    {
        field: "badge_type",
        headerName: "Badge Type",
        valueGetter: ({ row }) => row.fields["badge_type"],
        minWidth: 50,
        flex: 5,
        resizable: false
    }
]

export const getRegistrationTreePath = (reg: Registration): string[] => reg.type === RegistrationType.Primary ?
    [reg.id] :
    [reg.primaryRegistrationId, reg.id]

const initialState: GridInitialStatePro = {
    sorting: {
        sortModel: [{
            field: "name",
            sort: "asc"
        }]
    }
}

export const RegistrationsGrid: FC<RegistrationsGridProps> = ({
    registrations,
    selectedRegistration,
    onSelectRegistration,
    disableTreeData,
    sx,
}) => {
    const selectionModel: GridInputSelectionModel = useMemo(
        () => selectedRegistration?.id ? [selectedRegistration.id] : [],
        [selectedRegistration?.id]
    )
    const onSelectionModelChange = useCallback(
        (model: GridSelectionModel) => onSelectRegistration(registrations.find(it => it.id === model[0])),
        [registrations, onSelectRegistration]
    )

    const newLocal = useCallback(
        ({ row }: GridRowClassNameParams<Registration>) => `approval-status-${row.approvalState}`,
        []
    )

    const combinedStyle = useMemo(() => ({
        ...sx,
        "& .MuiDataGrid-cell:focus": noOutline,
        "& .MuiDataGrid-cell:focus-within": noOutline,
        "& .MuiDataGrid-columnHeader:focus": noOutline,
        "& .MuiDataGrid-columnHeader:focus-within": noOutline,
        "& .MuiDataGrid-row.approval-status-Pending": notApproved,
        "& .MuiDataGrid-row.approval-status-Cancelled": notApproved,
        "& .MuiDataGrid-row.approval-status-Rejected": notApproved,
    }), [sx])

    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

    const columns = useMemo(
        () => buildColumns(isXs),
        [isXs]
    )
    const columnVisibilityModel = useMemo(
        () => getColumnVisibility(isXs),
        [isXs]
    )
    const rowHeight = useMemo(
        () => isXs ? 70 : 55,
        [isXs]
    )

    return <DataGridPro
        rows={registrations}
        columns={columns}
        treeData={!disableTreeData}
        getTreeDataPath={getRegistrationTreePath}
        defaultGroupingExpansionDepth={1}
        columnVisibilityModel={columnVisibilityModel}
        initialState={initialState}
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        rowHeight={rowHeight}
        disableColumnReorder
        disableColumnMenu
        disableMultipleSelection
        hideFooter
        getRowClassName={newLocal}
        sx={combinedStyle}
    />
}

const noOutline = {
    outline: "none"
}

const notApproved = {
    opacity: 0.5
}