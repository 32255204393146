import { Registration } from "@marketpartner/backend-api"
import { fullName } from "@marketpartner/mp-common"
import { Stack, SxProps, Typography } from "@mui/material"
import { FC } from "react"


export type NameAndEmailProps = {
    registration: Registration
    status?: string
    sx?: SxProps
}

export const NameAndEmail: FC<NameAndEmailProps> = ({
    registration,
    status,
    sx,
}) => {
    return <Stack sx={sx}>
        <Stack direction="row" spacing={1} sx={{ alignItems: "baseline" }}>
            <Typography variant="body1">
                {fullName(registration)}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: "italic" }}>
                {status}
            </Typography>
        </Stack>
        <Typography variant="body2" color="text.secondary">
            {registration.email}
        </Typography>
    </Stack>
}