import SearchIcon from "@mui/icons-material/Search"
import { FC, useState } from "react"
import { ResponsiveIconButton, ResponsiveIconButtonProps } from "src/common/ResponsiveIconButton"
import { useInteractionOverlay } from "src/interaction/interaction-overlay-context"
import { useLockingContext } from "src/locking/locking-context"
import { RegistrationSearchDialog } from "src/registrations/RegistrationSearchDialog"


export const RegistrationSearchButton: FC<ResponsiveIconButtonProps> = ({
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const overlay = useInteractionOverlay()
    const { hideRegistrationList } = useLockingContext()

    if (hideRegistrationList) {
        return <></>
    }

    return <>
        <RegistrationSearchDialog open={isOpen} onClose={() => setIsOpen(false)} />
        <ResponsiveIconButton
            {...props}
            onClick={() => setIsOpen(true)}
            disabled={overlay.isOpen}
        >
            <SearchIcon />
        </ResponsiveIconButton>
    </>
}