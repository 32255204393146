import { Box, BoxProps, IconProps } from "@mui/material"
import { AllSystemCSSProperties, ResponsiveStyleValue } from "@mui/system/styleFunctionSx"
import { cloneElement, FC, ReactElement } from "react"

export type NestedIconProps = BoxProps & {
    baseIcon: ReactElement<IconProps>
    nestedIcon: ReactElement<IconProps>
    fontSize: ResponsiveStyleValue<AllSystemCSSProperties["fontSize"]>
}

export const NestedIcon: FC<NestedIconProps> = ({
    baseIcon,
    nestedIcon,
    sx,
    fontSize,
    ...props
}) => {
    return <Box
        {...props}
        sx={{
            ...sx,
            position: "relative",
            display: "flex",
            fontSize,
        }}
    >
        {cloneElement(baseIcon, {
            fontSize: "inherit",
            sx: {
                ...baseIcon.props.sx,
                fontSize: "100%",
            },
        })}
        {cloneElement(nestedIcon, {
            fontSize: "inherit",
            sx: {
                ...nestedIcon.props.sx,
                position: "absolute",
                bottom: 0,
                right: 0,
                transform: "translateY(35%) translateX(35%)",
                fontSize: "55%",
            },
        })}
    </Box>
}