import { FormFieldValue, ObjectFieldValueType, Registration, RegistrationType, toCsvValue } from "@marketpartner/backend-api"
import { fullName } from "@marketpartner/mp-common"
import { DateTime } from "luxon"
import { Avatar, Stack, SxProps, Typography } from "@mui/material"
import { FC, ReactNode } from "react"

export type RegistrationDetailsProps = {
    registration: Registration
    sx?: SxProps
}

export const RegistrationDetails: FC<RegistrationDetailsProps> = ({
    registration,
    sx,
}) => {
    const profile_photo_url = registration.profile.profile_picture?.absoluteUrl ?? registration.fields.profile_picture?.absoluteUrl
    return <Stack sx={{ alignItems: "center", ...sx }}>
        <Typography variant="h4">
            {fullName(registration)}&nbsp;
            ({registration.type === RegistrationType.Guest ? "Guest" : registration.categoryId})
        </Typography>
        {profile_photo_url && <Avatar
            src={profile_photo_url}
            alt={fullName(registration)}
            sx={{ width: 200, height: 200, mb: 2, marginTop: 2 }}
            variant="rounded"
        />}
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2 }}>
            {registration.email}
        </Typography>
        <RegDetail label="Approval state" value={registration.approvalState} />
        {Object.entries(registration.fields)
            .filter(([name]) => !["email", "first_name", "last_name"].includes(name))
            .map(([name, value]) => <RegDetail
                key={name}
                label={name}
                value={convertToDisplayableValue(value)}
            />)
        }
    </Stack>
}

type RegDetailProps = {
    label: ReactNode
    value: ReactNode
}

const RegDetail: FC<RegDetailProps> = ({
    label,
    value,
}) => {
    return <Stack direction="row" spacing={0.5}>
        <Typography sx={{ fontWeight: "bold" }}>{label}:</Typography>
        <Typography>{value}</Typography>
    </Stack >
}

function convertToDisplayableValue(value: unknown): string {
    if (value === undefined || value === null) {
        return ""
    }
    if (typeof value === 'string') {
        return value
    }
    if (typeof value === 'number') {
        return value.toString()
    }
    if (Array.isArray(value)) {
        return value.map(convertToDisplayableValue).join(",")
    }
    if (value instanceof DateTime) {
        return value.toISO()
    }
    if (typeof value === "object" && "type" in value && Object.values(ObjectFieldValueType).includes(value.type as any)) {
        return toCsvValue(value as FormFieldValue)
    }
    return value.valueOf().toString()
}
