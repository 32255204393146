import CloseIcon from "@mui/icons-material/Close"
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mui/material"
import { FC, useState } from "react"
import { ResponsiveDialog } from "src/common/ResponsiveDialog"
import { ResponsiveIconButton } from "src/common/ResponsiveIconButton"
import { FormSelect } from "src/forms/FormSelect"
import { LockSettings } from "src/settings/LockSettings"
import { PrintSettings } from "src/settings/PrintSettings"
import { ScannerSettings, useScannerSettings } from "src/settings/scanner-settings-context"
import { SessionIdInput } from "src/settings/SessionIdInput"
import { SimpleToggle } from "src/settings/SimpleToggle"
import { SwitchEventButton } from "src/settings/SwitchEventButton"

export type SettingsDialogProps = {
    open: boolean
    onClose: () => void
}

export const SettingsDialog: FC<SettingsDialogProps> = ({
    open,
    onClose,
}) => {
    return <ResponsiveDialog
        open={open}
        onClose={onClose}
    >
        <SettingsDialogContent onClose={onClose} />
    </ResponsiveDialog>
}

const SettingsDialogContent: FC<{
    onClose: () => void
}> = ({ onClose }) => {
    const settings = useScannerSettings()
    const [printingEnabled, setPrintingEnabled] = useState(settings.printingEnabled)
    const [printCapsNames, setPrintCapsNames] = useState(settings.printCapsNames)
    const [sessionId, setSessionId] = useState(settings.sessionId)
    const [formId, setFormId] = useState(settings.formId)
    const [enableRepeatScans, setEnableRepeatScans] = useState(settings.enableRepeatScans)
    const [lockLevel, setLockLevel] = useState(settings.lockLevel)
    const [requireScanConfirmation, setRequireScanConfirmation] = useState(settings.requireScanConfirmation)

    const newSettings: Omit<ScannerSettings, "id"> = {
        printingEnabled,
        printCapsNames,
        sessionId,
        formId,
        enableRepeatScans,
        lockLevel,
        requireScanConfirmation,
    }

    const settingsChanged = Object.keys(newSettings)
        .some(key => (settings as any)[key] !== (newSettings as any)[key])

    const handleSave = () => {
        settings.save(newSettings)
        onClose()
    }

    return <>
        <Stack flexDirection="row" sx={{
            alignItems: "center",
        }}>
            <DialogTitle>
                Scanner settings
            </DialogTitle>
            <Box sx={{ flex: "1" }} />
            <ResponsiveIconButton onClick={() => onClose()} sx={{ m: 1 }}>
                <CloseIcon />
            </ResponsiveIconButton>
        </Stack>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography color="text.secondary">
                        Scanner ID: {settings.id}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SessionIdInput
                        value={sessionId}
                        onChange={setSessionId}
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormSelect
                        formId={formId}
                        onChange={setFormId}
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PrintSettings
                        printingEnabled={printingEnabled}
                        setPrintingEnabled={setPrintingEnabled}
                    />
                    {printingEnabled &&
                        <SimpleToggle
                            label="Print capitalized names"
                            checked={printCapsNames}
                            onChange={setPrintCapsNames}
                        />
                    }
                    <SimpleToggle
                        label="Enable repeat scans"
                        checked={enableRepeatScans}
                        onChange={setEnableRepeatScans}
                    />
                    <SimpleToggle
                        label="Require manual confirmation of each scan"
                        checked={requireScanConfirmation}
                        onChange={setRequireScanConfirmation}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LockSettings
                        lockLevel={lockLevel}
                        onChangeLockLevel={setLockLevel}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <SwitchEventButton onSwitch={onClose} />
            <Box sx={{ flex: "1" }} />
            <Button
                variant="contained"
                disabled={!settingsChanged}
                onClick={handleSave}
            >
                Save
            </Button>
        </DialogActions>
    </>
}