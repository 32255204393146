import { Button, ButtonProps } from "@mui/material"
import { FC } from "react"


export type SubmitButtonProps = ButtonProps

export const SubmitButton: FC<SubmitButtonProps> = ({
    sx,
    ...props
}) => {
    return <Button
        size="large"
        variant="contained"
        {...props}
        sx={{
            my: 2,
            mx: "auto",
            width: "100%",
            maxWidth: theme => theme.breakpoints.values.md,
            ...sx,
        }}
    />
}