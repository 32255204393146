import { createContext, FC, ReactNode, useContext } from 'react'
import { useEventStorage } from 'src/common/useEventStorage'
import { LockLevel } from 'src/locking/LockLevel'
import { v4 as uuidV4 } from 'uuid'

export type ScannerSettings = {
    id: string
    sessionId: string | null
    printingEnabled: boolean
    printCapsNames: boolean
    formId: number | null
    enableRepeatScans: boolean
    lockLevel: LockLevel
    requireScanConfirmation: boolean
}

const defaultSettings: ScannerSettings = {
    id: uuidV4(),
    sessionId: null,
    printingEnabled: false,
    printCapsNames: false,
    formId: null,
    enableRepeatScans: false,
    lockLevel: LockLevel.Open,
    requireScanConfirmation: false,
}

const context = createContext<ScannerSettings & {
    save: (newSettings: Partial<ScannerSettings>) => void
}>(undefined!)

export const useScannerSettings = () => useContext(context)

export type ScannerSettingsProviderProps = {
    children: ReactNode
}

export const ScannerSettingsProvider: FC<ScannerSettingsProviderProps> = ({
    children
}) => {
    const [storedSettings, setStoredSettings] = useEventStorage<Partial<ScannerSettings>>("scanner-settings", defaultSettings)

    // Include newly added defaults in case they are missing in LS from an earlier version
    const settings = {
        ...defaultSettings,
        ...storedSettings
    }

    const save = (newSettings: Partial<ScannerSettings>) => {
        setStoredSettings({
            ...settings,
            ...newSettings,
        })
    }

    return <context.Provider children={children} value={{
        ...settings,
        save,
    }} />
}