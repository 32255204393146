import { QrCodeScanner } from "@mui/icons-material";
import { Stack, SxProps, Typography } from "@mui/material";
import { FC } from "react";
import { useAttendanceTracker } from "src/attendance/attendance-tracker-context";
import { UploadStatusIcon } from "src/attendance/UploadStatusIcon";
import { NestedIcon } from "src/common/NestedIcon";

export type AttendanceCountProps = {
    sx?: SxProps
}

export const AttendanceCount: FC<AttendanceCountProps> = ({
    sx,
}) => {
    const { scannedTokens } = useAttendanceTracker()

    return <Stack
        direction="row"
        spacing={1}
        sx={{
            ...sx,
            alignItems: "center"
        }}
    >
        <NestedIcon
            baseIcon={<QrCodeScanner />}
            nestedIcon={<UploadStatusIcon />}
            fontSize={{
                "xs": "x-large",
                "sm": "xx-large",
            }}
        />
        <Typography
            sx={{
                fontSize: {
                    "xs": "medium",
                    "sm": "large",
                }
            }}
        >
            {scannedTokens.size}
        </Typography>
    </Stack >
}