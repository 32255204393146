import { Paper, Typography } from "@mui/material"
import { FC } from "react"
import { LoginForm } from 'src/login/LoginForm'


export const LoginScreen: FC = () => {
    return <Paper sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
        width: "fit-content",
        height: "fit-content",
        padding: 2
    }}>
        <Typography variant="h5" component="h1" sx={{
            mb: 2,
        }}>
            Login to scanner
        </Typography>
        <LoginForm />
    </Paper>
}

