import { Registration } from "@marketpartner/backend-api"
import { useEffect } from "react"

export type SelectedToken = {
    registration?: Registration
    token: string
    isRepeatScan: boolean
}

export type SelectionCallback = (selection: SelectedToken) => void

const selectionCallbacks = new Set<SelectionCallback>()

export const useSelectionCallback = (
    callback: SelectionCallback
) => {
    useEffect(() => {
        selectionCallbacks.add(callback)
        return () => {
            selectionCallbacks.delete(callback)
        }
    }, [callback])
}

export const notifySelectionCallbacks = (selection: SelectedToken) => {
    selectionCallbacks.forEach(callback =>
        callback(selection)
    )
}