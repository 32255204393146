import { Close } from "@mui/icons-material"
import { Stack, Typography, useTheme } from "@mui/material"
import { FC, useEffect } from "react"
import { FullscreenScanResultOverlay } from "src/interaction/FullscreenScanResultOverlay"
import { useInteractionOverlay } from "src/interaction/interaction-overlay-context"

const timeoutMs = 3_000

export const FullscreenRepeatScanOverlay: FC = () => {
    const { close } = useInteractionOverlay()
    const theme = useTheme()

    useEffect(() => {
        const timer = setTimeout(close, timeoutMs)
        return () => {
            clearTimeout(timer)
        }
    }, [close])

    return <FullscreenScanResultOverlay
        color={theme.palette.warning.main}
    >
        <Stack sx={{
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.warning.contrastText,
        }}>
            <Close sx={{
                fontSize: "30vmin",
            }} />
            <Typography fontSize="10vmin">
                Already scanned
            </Typography>
        </Stack>
    </FullscreenScanResultOverlay>
}