import { Registration } from "@marketpartner/backend-api"
import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from "react"
import { useAttendanceTracker } from "src/attendance/attendance-tracker-context"
import { notifySelectionCallbacks, SelectedToken } from "src/dispatch/useSelectionCallback"
import { useScannerSettings } from "src/settings/scanner-settings-context"

export type SelectionDispatchContext = {
    onScanRegistration: (registration: Registration) => unknown
    onScanToken: (token: string) => unknown
    onSelectRegistration: (registration: Registration) => unknown

    pendingSelection?: SelectedToken
    confirmPendingSelection: () => void
    cancelPendingSelection: () => void
}

const context = createContext<SelectionDispatchContext>(undefined!)

export const useSelectionDispatch = () => useContext(context)

type SelectionDispatchContextProviderProps = {
    children: ReactNode
}

/**
 * Manages the selection process:
 * 
 * - Dispatches the selected/scanned token/registration event to all hooks
 * - If configured, holds selected/scanned token/registration in a pending state for manual checking
 *   before dispatching.
 */
export const SelectionDispatchContextProvider: FC<SelectionDispatchContextProviderProps> = ({
    children
}) => {
    const { scannedTokens } = useAttendanceTracker()
    const settings = useScannerSettings()
    const [pendingSelection, setPendingSelection] = useState<SelectedToken>()

    const scanOrSelect = useCallback((token: string, registration?: Registration, isRepeatScan = false) => {
        const selection: SelectedToken = { token, registration, isRepeatScan }
        if (settings.requireScanConfirmation) {
            setPendingSelection(selection)
        } else {
            notifySelectionCallbacks(selection)
        }
    }, [settings.requireScanConfirmation])

    const confirmPendingSelection = useCallback(
        () => {
            setPendingSelection(undefined)
            pendingSelection && notifySelectionCallbacks(pendingSelection)
        },
        [pendingSelection]
    )

    const isRepeatScan = useCallback(
        (token: string) => !settings.enableRepeatScans && scannedTokens.has(token),
        [settings.enableRepeatScans, scannedTokens]
    )

    return <context.Provider children={children} value={useMemo(() => ({
        onScanRegistration: (registration: Registration) => scanOrSelect(
            registration.accessToken,
            registration,
            isRepeatScan(registration.accessToken)
        ),
        onScanToken: (token: string) => scanOrSelect(
            token,
            undefined,
            isRepeatScan(token)
        ),
        onSelectRegistration: (registration: Registration) => scanOrSelect(
            registration.accessToken,
            registration
        ),

        pendingSelection,
        confirmPendingSelection,
        cancelPendingSelection: () => setPendingSelection(undefined),
    }), [pendingSelection, confirmPendingSelection, scanOrSelect, isRepeatScan])} />
}