import { useLocalStorage } from "@marketpartner/mp-common-react"
import { UseStorage } from "@marketpartner/mp-common-react/dist/hooks/useStorage"
import { useEvent } from "src/common/event-context"


export const useEventStorage = <T>(
    key: string,
    defaultValue: T
): UseStorage<T> => {
    const event = useEvent()
    const fullKey = `event:${event.clientId}:${event.id}:${key}`
    return useLocalStorage(fullKey, defaultValue)
}