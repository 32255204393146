import { CheckCircle, Upload, Warning } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { FC } from "react";
import { useAttendanceTracker } from "src/attendance/attendance-tracker-context";
import { BackgroundUpdateState } from "src/common/background-update/background-update-storage";
import { useFormContext } from "src/forms/form-context";


export const UploadStatusIcon: FC<SvgIconProps> = ({
    ...props
}) => {
    const attendance = useAttendanceTracker()
    const forms = useFormContext()
    const uploads = [
        ...attendance.queuedScans,
        ...forms.queuedResponses,
    ]

    if (uploads.some(it => it.state === BackgroundUpdateState.Failed)) {
        return <Warning color="warning" {...props} />
    }
    if (uploads.length > 0) {
        return <Upload color="info" {...props} />
    }
    return <CheckCircle color="success" {...props} />
}