import { Box } from "@mui/system"
import { FC } from "react"
import { zIndex } from "src/common/zIndex"
import { HeaderBar } from "src/layout/HeaderBar"
import { LockControl } from "src/locking/LockControl"
import { InteractiveQrCodeScanner } from "src/scanning/InteractiveQrCodeScanner"
import { SettingsButton } from "src/settings/SettingsButton"


export const AppLayout: FC = () => {
    return <>
        <Box sx={{
            height: "100%",
            display: "grid",
            gridTemplateRows: "auto 1fr",
            justifyItems: "center",
            alignItems: "center",
        }}>
            <HeaderBar position="relative" />
            <InteractiveQrCodeScanner sx={{
                maxWidth: "100vmin",
                maxHeight: "100vmin",
                height: "100%",
            }} />
        </Box>
        <LockControl sx={{
            position: "fixed",
            bottom: 12,
            left: 12,
            zIndex: zIndex.videoOverlay + 1,
        }} />
        <SettingsButton sx={{
            position: "fixed",
            bottom: 12,
            right: 12,
            zIndex: zIndex.videoOverlay + 1,
        }} />
    </>
}