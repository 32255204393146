import { ScanningConfiguration } from "@marketpartner/backend-api"
import { createContext, FC, ReactNode, useContext } from "react"
import { backend } from "src/common/backend"
import { useEvent } from "src/common/event-context"
import { LoadingSpinner } from "src/common/LoadingSpinner"


const context = createContext<ScanningConfiguration>(undefined!)

export const useScanningConfiguration = () => useContext(context)

export type ScanningConfigurationProviderProps = {
    children: ReactNode
}

export const ScanningConfigurationProvider: FC<ScanningConfigurationProviderProps> = ({
    children
}) => {
    const event = useEvent()
    const { data } = backend.scanning.useGetConfiguration([event.clientId, event.id], {
        refetchInterval: 10_000
    })

    if (!data) {
        return <LoadingSpinner />
    }

    return <context.Provider children={children} value={data} />
}