import CloseIcon from "@mui/icons-material/Close"
import { Stack, Typography, useTheme } from "@mui/material"
import { FC } from "react"
import { FullscreenScanResultOverlay } from "src/interaction/FullscreenScanResultOverlay"


export const FullscreenUnknownTokenOverlay: FC = () => {
    const theme = useTheme()

    return <FullscreenScanResultOverlay
        color={theme.palette.warning.main}
    >
        <Stack sx={{
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.warning.contrastText,
        }}>
            <CloseIcon sx={{
                fontSize: "30vmin",
            }} />
            <Typography fontSize="8vmin">
                Code not recognized
            </Typography>
            <Typography fontSize="6vmin">
                Attendance is still recorded
            </Typography>
            <Typography fontSize="4vmin">
                (tap to dismiss)
            </Typography>
        </Stack>
    </FullscreenScanResultOverlay>
}