import { DialogContent, Stack } from "@mui/material"
import { FC } from "react"
import { useSelectionDispatch } from "src/dispatch/selection-dispatch-context"
import { InteractionHeader } from "src/interaction/InteractionHeader"
import { RegistrationDetails } from "src/interaction/RegistrationDetails"
import { SubmitButton } from "src/interaction/SubmitButton"

export const ConfirmSelection: FC = () => {
    const { pendingSelection, confirmPendingSelection, cancelPendingSelection, } = useSelectionDispatch()

    if (!pendingSelection) {
        return <></>
    }
    const registration = pendingSelection.registration

    return <>
        <InteractionHeader selection={pendingSelection} close={cancelPendingSelection} />
        <DialogContent>
            <Stack spacing={4} sx={{ alignItems: "center" }}>
                {registration && <RegistrationDetails registration={registration} sx={{ mt: 2 }} />}
                <SubmitButton onClick={confirmPendingSelection}>
                    Confirm
                </SubmitButton>
            </Stack>
        </DialogContent>
    </>
}