import { Box } from "@mui/material"
import { FC, ReactNode } from "react"
import { useInteractionOverlay } from "src/interaction/interaction-overlay-context"


export type FullscreenScanResultOverlayProps = {
    children: ReactNode
    color: string
}

export const FullscreenScanResultOverlay: FC<FullscreenScanResultOverlayProps> = ({
    children,
    color,
}) => {
    const { close } = useInteractionOverlay()

    return <Box
        children={children}
        onClick={close}
        sx={{
            backgroundColor: color,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
    />
}
