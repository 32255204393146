import LogoutIcon from "@mui/icons-material/Logout"
import { Button } from "@mui/material"
import { FC, useState } from "react"
import { SwitchEventPopup } from "src/login/SwitchEventPopup"

export type SwitchEventButtonProps = {
    onSwitch: () => unknown
}

export const SwitchEventButton: FC<SwitchEventButtonProps> = ({
    onSwitch
}) => {
    const [isSwitchEventOpen, setIsSwitchEventOpen] = useState(false)

    const handleSwitchEvent = () => {
        setIsSwitchEventOpen(true)
    }

    return <>
        <Button
            onClick={handleSwitchEvent}
            startIcon={<LogoutIcon />}
            color="inherit"
        >
            Switch event
        </Button>
        <SwitchEventPopup 
            isOpen={isSwitchEventOpen} 
            onClose={() => setIsSwitchEventOpen(false)}
            onSwitch={onSwitch}
        />
    </>
}