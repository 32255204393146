import CheckIcon from "@mui/icons-material/Check"
import WarningIcon from "@mui/icons-material/Warning"
import { Collapse, FormHelperText, Stack } from "@mui/material"
import { FC } from "react"
import { useScanningConfiguration } from "src/settings/scanning-configuration-context"
import { SimpleToggle } from "src/settings/SimpleToggle"

export type PrintSettingsProps = {
    printingEnabled: boolean
    setPrintingEnabled: (printingEnabled: boolean) => void
}

export const PrintSettings: FC<PrintSettingsProps> = ({
    printingEnabled,
    setPrintingEnabled,
}) => {
    return <Stack>
        <SimpleToggle
            label="Printing enabled"
            checked={printingEnabled}
            onChange={setPrintingEnabled}
        />
        <PrintFormat show={printingEnabled} />
    </Stack>
}

const PrintFormat: FC<{
    show: boolean
}> = ({ show }) => {
    const configuration = useScanningConfiguration()

    return <Collapse in={show}>
        <Stack
            direction="row"
            spacing={1}
            sx={{ alignItems: "center" }}
        >
            {configuration.printFormat ?
                <>
                    <CheckIcon fontSize="inherit" color="success" />
                    <FormHelperText>
                        Print format configured.
                    </FormHelperText>
                </> :
                <>
                    <WarningIcon fontSize="inherit" color="warning" />
                    <FormHelperText>
                        No print format configured in admin console.
                    </FormHelperText>
                </>
            }
        </Stack>
    </Collapse>
}