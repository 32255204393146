import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { FC } from "react"
import { ResponsiveDialog } from "src/common/ResponsiveDialog"
import { LoginForm } from "src/login/LoginForm"

export type SwitchEventPopupProps = {
    isOpen: boolean
    onClose: () => unknown
    onSwitch: () => unknown
}

export const SwitchEventPopup: FC<SwitchEventPopupProps> = ({
    isOpen,
    onClose,
    onSwitch,
}) => {
    const handleLogin = () => {
        onClose()
        onSwitch()
    }

    return <ResponsiveDialog
        open={isOpen}
        onClose={onClose}
    >
        <DialogTitle>Switch event</DialogTitle>
        <DialogContent>
            <LoginForm onLogin={handleLogin} />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                Cancel
            </Button>
        </DialogActions>
    </ResponsiveDialog>
}