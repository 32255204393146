
export enum Environment {
    Dev = "Dev",
    Prod = "Prod"
}

export const getEnvironment = (): Environment => {
    if (window.location.hostname === "scanner.marketpartner.app") {
        return Environment.Prod
    }
    return Environment.Dev
}

export const getAdminUrl = (clientId: string, eventId: string, eventSubpath?: string): string => {
    const host = getEnvironment() === Environment.Prod ?
        "marketpartner.app" :
        "dev.marketpartner.app"

    return `https://${host}/#/clients/${clientId}/events/${eventId}/${eventSubpath}`
}